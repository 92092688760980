<template>
  <div class="horizontal-card__media">
    <div
      v-if="media.imageOrVideo === 'image'"
      class="horizontal-card__media__image__container"
    >
      <nuxt-img
        class="horizontal-card__media__image"
        :src="`${
          $device.isDesktop ? media.desktopImage.url : media.mobileImage.url
        }`"
        :alt="media.desktopImage.alt"
      />
    </div>
    <div v-else class="horizontal-card__media__video">
      <client-only>
        <VueVimeoPlayer
          v-if="getVimeoPlayerOptions()"
          v-show="!loadingVideo"
          @loaded="loadingVideo = false"
          v-bind="getVimeoPlayerOptions()"
        />
        <LoadingDots v-show="loadingVideo" />
      </client-only>
    </div>
  </div>
</template>

<script>
import { defineComponent, ref } from '@nuxtjs/composition-api';
import { vueVimeoPlayer } from 'vue-vimeo-player';
import LoadingDots from '~/components/General/FormElements/LoadingDots.vue';

export default defineComponent({
  name: 'HorizontalCardMedia',
  components: { VueVimeoPlayer: vueVimeoPlayer, LoadingDots },
  props: {
    media: {
      type: Object,
      required: true,
    },
  },
  setup(props) {
    const loadingVideo = ref(true);

    const getVimeoPlayerOptions = () => {
      if (typeof window !== 'undefined') {
        const vimeoPlayerOptions = {
          autoplay: true,
          controls: false,
          loop: true,
          options: {
            muted: true,
            autopause: false,
          },
        };

        const windowWidth = window.innerWidth;
        if (windowWidth < 1024) {
          vimeoPlayerOptions['player-width'] = windowWidth - 32;
        }

        const correctVideo =
          props.media.imageOrVideo === 'video' && props.media.videoUrl;
        if (!correctVideo) return null;
        vimeoPlayerOptions[
          correctVideo.includes('http') ? 'video-url' : 'video-id'
        ] = correctVideo;
        return vimeoPlayerOptions;
      }
      return null;
    };

    return {
      getVimeoPlayerOptions,
      loadingVideo,
    };
  },
});
</script>

<style lang="scss" scoped>
.horizontal-card__media {
  &__image {
    width: 100%;
    height: 100%;
    object-fit: cover;
    &__container {
      display: flex;
      justify-content: center;
      height: 100%;
      width: 100%;
    }
  }
  &__video {
    @include flex-center;
  }
}
@include from-desktop-min {
  .horizontal-card__media {
    &__video {
      width: 100%;
      padding: 2rem 4.8125rem;
    }
  }
}
</style>
