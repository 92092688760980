<template>
  <div
    v-if="blockData.direction"
    class="horizontal-card"
    :class="`horizontal-card__${blockData.direction} horizontal-card-${blockData.layout}`"
  >
    <HorizontalCardMedia
      class="horizontal-card__media-section"
      :media="blockData"
    />
    <HorizontalCardDetails
      class="horizontal-card__details-section"
      :details="blockData"
    />
  </div>
</template>

<script>
import { defineComponent } from '@nuxtjs/composition-api';
import {
  HorizontalCardDetails,
  HorizontalCardMedia,
} from './HorizontalCardInternal';

export default defineComponent({
  name: 'HorizontalCard',
  components: {
    HorizontalCardDetails,
    HorizontalCardMedia,
  },
  props: {
    blockData: {
      type: Object,
      required: true,
    },
  },
});
</script>

<style lang="scss" scoped>
.horizontal-card {
  margin: 5rem auto;
  &__media {
    grid-area: media;
  }
  &__details {
    grid-area: details;
  }
  &-standard {
    @include desktop-boxed-inner;
  }
  &-boxed {
    background-color: var(--c-white);
    .horizontal-card__details-section {
      padding: 2rem 0;
    }
  }
}
@include to-tablet-max {
  .horizontal-card {
    padding: 0 0.9375rem;
    &-boxed {
      padding: 4rem 1.25rem 2rem;
    }
  }
}
@include from-tablet-min {
  .horizontal-card {
    display: grid;
    grid-template-columns: 50% 50%;
    &-boxed {
      margin-right: 1.25rem;
      margin-left: 1.25rem;
      .horizontal-card__details-section {
        padding: 2rem;
      }
    }
    &__right {
      grid-template-areas: 'details media';
      .horizontal-card__details-section {
        padding-right: 2rem;
      }
    }
    &__left {
      grid-template-areas: 'media details';
      .horizontal-card__details-section {
        padding-left: 2rem;
      }
    }
  }
}
</style>
